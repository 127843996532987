<template>
    <article class="page-center padded form-wrapper" v-html="content"></article>
</template>

<script>
import documentService from "@/services/documentLoader.js";
export default {
    data(){return {
        content:""
    }},
    created(){
        let s=this;
        documentService.getDocument("404.md").then(data=>{
            s.$set(s, "content", data)
        },()=>{})
    }
};
</script>

<style scoped>
article >>> img {
    max-width: 98%;
    text-align: center;
}
</style>